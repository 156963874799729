.step-showcase-home {
  top: 10vh;
  left: 0;
  right: 0;
  height: 50px;

  display: flex;
  justify-content: center;
  & > div {
    height: 100%;
    width: 150px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #4caf50, #409743);
    background-color: #4caf50;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 20px;
    font-family: "Lilita One", sans-serif;
    font-weight: 400;

    box-shadow: 0px 5px 0px green;
  }
}
