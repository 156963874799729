.upgrade-container-home {
  left: 0;
  right: 20vw;
  bottom: 0;
  top: 50vh;

  animation: slideInFromBottom 0.5s forwards;

  padding: 10px;

  border-top: 5px solid black;

  background-image: linear-gradient(to bottom, #1e6921, #0e320f);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > h2 {
    font-size: 25px;
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    text-align: center;

    text-decoration: none;
    color: white;
  }
  & > div {
    flex-basis: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    bottom: -50vh;
    top: 100vh;
  }
  to {
    bottom: 0vh;
    top: 50vh;
  }
}

.upgrade-container-page {
  left: 20vw;
  right: 20vw;
  bottom: 0;
  top: 0;

  animation: fadeIn 0.5s 0.75s both;

  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > h2 {
    font-size: 25px;
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    text-align: center;

    text-decoration: none;
    color: white;
  }
  & > div > div {
    flex-basis: 1;
    margin-top: 20px;
  }

  & p.quantity {
    margin: 0;
    font-size: 18px;
    color: white;
    text-align: center;
  }
}

div.upgrades-animation-top {
  background-color: rgba(14, 50, 15, 0.7);

  top: 0;
  bottom: 50vw;
  left: 20vw;
  right: 20vw;

  animation: slideInFromTop 0.5s forwards;
}

div.upgrades-animation-bottom {
  background-color: rgba(14, 50, 15, 0.7);

  top: 50vw;
  bottom: 0;
  left: 20vw;
  right: 20vw;

  animation: slideInFromBottom 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    top: -50vh;
    bottom: 100vh;
  }
  to {
    top: 0vh;
    bottom: 50vh;
  }
}

@keyframes slideInFromBottom {
  from {
    bottom: -50vh;
    top: 100vh;
  }
  to {
    bottom: 0vh;
    top: 50vh;
  }
}
