.stats-wrapper {
  bottom: 0;
  top: 0;
  left: 50vw;
  right: 0;

  background-image: linear-gradient(
    to bottom,
    rgba(14, 50, 15, 0.7),
    rgba(8, 30, 9, 0.99)
  );
  background-color: #00000022;

  animation: SlideInFromLeft 0.5s forwards;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 5px;
  text-align: center;
  gap: 10px;
  padding-bottom: 50px;

  & > h2 {
    font-size: 25px;
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    color: lightgreen;
  }

  & > p {
    margin: 0;
    color: white;
  }
}

@keyframes SlideInFromLeft {
  from {
    right: -50vw;
    left: 100vw;
  }
  to {
    right: 0vw;
    left: 50vw;
  }
}
