.custom-input {
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 400;

  border: #4caf50 2px solid;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 2px 5px black;
  text-align: center;

  &::placeholder {
    color: lightgray;
    font-size: 20px;
  }
  &.focus {
    border: #3b8a3e 2px solid;
  }
}
