.login-form {
  top: 30vh;
  height: 10vh;
  left: 20vw;
  right: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.login-button {
  bottom: 10vh;
  top: 80vh;
  left: 10vw;
  right: 10vw;
}
