.settings-page-wrapper {
  top: 0;
  bottom: 0;
  left: 20vw;
  right: 20vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;

  background-color: rgba(14, 50, 15, 0.7);

  padding-top: 20vh;
  color: white;
  font-size: 20px;
  & a {
    color: white;
  }
}
