.fixed {
  position: fixed;
}

body {
  background-color: white;
}

h1 {
  margin-top: 0;
}

img.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: stretch;
}

div.img-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.img-background > img {
  max-width: 100%;
  max-height: 100%;
}

img.fluid {
  width: 100%;
}

.z-top-3 {
  z-index: 30;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
