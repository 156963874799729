.logout-button {
  bottom: 15vh;
  top: 75vh;
  left: 10vw;
  right: 10vw;
}

div.logout-image {
  top: 0vh;
  bottom: 20vh;
  left: 0;
  right: 0;

  & > img {
    width: 100%;
    height: 80vh;
    object-fit: contain;
  }
}
