.menu-container-home {
  right: 0;
  left: 80vw;

  top: 50vh;
  bottom: 0;

  background-image: linear-gradient(
    to bottom,
    rgba(14, 50, 15, 0.7),
    rgba(8, 30, 9, 0.99)
  );
  background-color: #00000022;

  padding: 10px;

  border-top: 5px solid black;

  animation: slideInFromBottom 0.5s 0.25s both;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  & img {
    border-radius: 15px;
    box-shadow: 0px 4px 0px black;
  }
}

@keyframes slideInFromBottom {
  from {
    bottom: -50vh;
    top: 100vh;
  }
  to {
    bottom: 0vh;
    top: 50vh;
  }
}
