div.main-menu {
  left: 0;
  right: 0;
  bottom: 0;
  top: 50vh;

  background-image: linear-gradient(to bottom, #1e6921, #0e320f);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 10px;
  & > * {
    flex-basis: 100%;
  }

  & > .steps {
    font-size: 30px;
    color: white;
    font-weight: 700;
    text-align: center;
  }
}
