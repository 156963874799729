.register-form {
  top: 45vh;
  height: 15vh;
  left: 20vw;
  right: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.register-button {
  bottom: 15vh;
  top: 75vh;
  left: 10vw;
  right: 10vw;
}
