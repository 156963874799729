.back-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 50px;

  & > img {
    border-radius: 20px;
    box-shadow: 0px 0px 10px black;
  }
}
