div.page-title-wrapper {
  left: 10vw;
  right: 10vw;

  &.float-left {
    text-align: start;
  }

  &.float-center {
    text-align: center;
  }

  &.float-right {
    text-align: end;
  }

  &.small {
    top: 5vh;
    left: 20vw;
    right: 20vw;

    &.float-left {
      left: 10vw;
    }

    &.float-right {
      right: 10vw;
    }

    @media (max-width: 300px) {
      top: 3vh;
    }

    & > h1 {
      font-family: "Lilita One", sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 43px;
      letter-spacing: 0em;
      color: #01400a;
    }
  }

  &.small-middle {
    top: 20vh;

    & > h1 {
      font-family: "Lilita One", sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 43px;
      letter-spacing: 0em;
      color: #01400a;
    }
  }

  &.medium {
    top: 10vh;

    @media (max-height: 750px) {
      top: 6vh;
    }

    @media (max-height: 700px) {
      top: 5vh;
    }

    & > h1 {
      font-family: "Lilita One", sans-serif;
      font-size: 36px;
      font-weight: 400;
      line-height: 65px;
      letter-spacing: 0em;
      color: #01400a;
      margin-bottom: 0px;

      @media (max-width: 300px) {
        font-size: 30px;
      }
    }
  }

  &.big {
    top: 10vh;

    & > h1 {
      font-family: "Lilita One", sans-serif;
      font-size: 64px;
      font-weight: 400;
      letter-spacing: 0em;
      color: #01400a;
      margin-bottom: 0px;
    }
  }

  &.big-top {
    top: 5vh;

    @media (max-width: 300px) {
      top: 3vh;
    }

    & > h1 {
      font-family: "Lilita One", sans-serif;
      font-size: 64px;
      font-weight: 700;
      letter-spacing: 0em;
      color: #01400a;
      margin-bottom: 0px;
    }
  }

  &.big-middle {
    top: 25vh;
    & > h1 {
      font-family: "Lilita One", sans-serif;
      font-size: 64px;
      font-weight: 400;
      letter-spacing: 0em;
      color: #01400a;
      margin-bottom: 0px;
    }
  }

  & > p:not(.cursive) {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #ffffff;
    margin-top: 20px;
  }

  & > p.cursive {
    font-family: Indie Flower;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    color: #00ffc28f;
  }

  & > p.big {
    font-family: "Lilita One", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    color: #01400a;
    margin: 0;
  }
}
