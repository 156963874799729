.custom-button {
  border-radius: 50px;
  background-image: linear-gradient(to bottom, #4caf50, #409743);
  background-color: #4caf50;

  display: flex;
  justify-content: center;
  letter-spacing: 3px;
  align-items: center;
  width: 100%;
  height: 45%;

  font-size: 40px;
  font-family: "Lilita One", sans-serif;
  font-weight: 400;

  text-decoration: none;
  color: white;

  box-shadow: 0px 5px 0px green;

  &.pressed {
    transform: translateY(5px);
    box-shadow: none;
  }

  &.disabled {
    transform: translateY(5px);
    box-shadow: none;
    background-image: linear-gradient(to bottom, #728f73, #505750);
    background-color: #728f73;
    color: gray;
  }
}

.custom-button-upgrade {
  border-radius: 25px;
  background-image: linear-gradient(to bottom, #4caf50, #409743);
  background-color: #4caf50;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;

  font-size: 17px;
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  text-align: center;

  text-decoration: none;
  color: white;
  padding: 5px;
  box-sizing: border-box;

  box-shadow: 0px 5px 0px green;

  margin-bottom: 10px;

  &.pressed {
    transform: translateY(5px);
    box-shadow: none;
  }

  &.disabled {
    transform: translateY(5px);
    box-shadow: none;
    background-image: linear-gradient(to bottom, #728f73, #505750);
    background-color: #728f73;
    color: gray;
  }
}
