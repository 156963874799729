div.welcome-image {
  left: 0;
  right: 0;
  top: 15vh;
  height: 55vh;

  & > img {
    width: 100%;
    height: 60vh;
    object-fit: contain;
  }
}

.welcome-buttons {
  top: 75vh;
  left: 10vw;
  right: 10vw;
  bottom: 7vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accent {
  left: 0;
  right: 0;
  bottom: 0vh;
  top: 97vh;

  background-image: linear-gradient(to bottom, #4caf50, #409743);
  background-color: #4caf50;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: darkgreen;
}
